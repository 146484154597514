// Adding a Visual Grid while you develop. Set to 'true.'
$visual-grid: false;
$visual-grid-color: #ccc;
$visual-grid-index: front;
$visual-grid-opacity: .2;

// Change the grid settings
$column: 90px;
$gutter: 30px;
$grid-columns: 12;
$max-width:em(800);

// Breakpoints
$sm: new-breakpoint(min-width 0 2);
$smbg: new-breakpoint(min-width em(480) 4);
$md: new-breakpoint(min-width em(768) 6);
$mdbg: new-breakpoint(min-width em(1024) 8);
$hg: new-breakpoint(min-width em(1140) 12);

$mobile: new-breakpoint(max-width 500px 12);
$tablet: new-breakpoint(max-width 768px 12);

$laptop: new-breakpoint(max-width 1024px 12);

$height-check: new-breakpoint(max-height 870px 12);
$small-height-check: new-breakpoint(max-height 720px 12);

$sideways-phone: new-breakpoint(min-width 376px max-width 667px orientation landscape 12);
$iphone-5: new-breakpoint(max-width 320px 12);

$sideways-5: new-breakpoint(min-width 320px max-width 568px orientation landscape 12);

$old-mbp: new-breakpoint(max-width 1280px 12);


// Breakpoints that only come into play when using patterns from http://refills.bourbon.io
$medium-screen: em(640);
$large-screen: em(860);
$medium-screen-up: new-breakpoint(min-width $medium-screen 4);
$large-screen-up: new-breakpoint(min-width $large-screen 8);

// ------------------------------------------------------------
// Override Neat's grid settings
// Because sometimes shit doesn't work the way it should.
// Read about it here: http://www.joshfry.me/blog/2013/05/13/omega-reset-for-bourbon-neat
//
// This will stay until Thoughtbot or someone else builds-in a proper
// fix for this issue.
//
// https://github.com/joshfry/omega-reset-for-bourbon-neat
// Big ups to Josh Fry http://www.joshfry.me
// ------------------------------------------------------------

$column: golden-ratio(1em, 3) !default; // Column width
$gutter: golden-ratio(1em, 3) !default; // Gutter between each two columns

// ------------------------------------------------------------
// Set Bourbon's flex-grid variables to match what Neat is doing
// http://bourbon.io/docs/#flex-grid
// ------------------------------------------------------------

$fg-column: $column;
$fg-gutter: $gutter;

// ------------------------------------------------------------
// Omega Reset
// ------------------------------------------------------------

@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  &:nth-child(#{$nth}+1) { clear: none; }
}

// ------------------------------------------------------------
// How to use your Neat grid using Omega Reset
// ------------------------------------------------------------

// .some-selector {
// 	@include media($mobile) {
// 		@include span-columns(2);
// 		@include omega(2n);
// 	}
// 	@include media($md) {
// 		@include omega-reset(2n); // Reset the previous omega declaration set by the preceding rule
// 		@include span-columns(3); // Set your columns etc.
// 		@include omega(2n); // Set omega for your tastes here, this now correctly overrides the previous omega rule.
// 	}
// 	@include media($mdbg) { // Wash. Rinse. Repeat
// 		@include omega-reset(2n);
// 		@include span-columns(2);
// 		@include omega(4n);
// 	}
// 	@include media($hg) {
// 		@include omega-reset(4n);
// 		@include span-columns(2);
// 		@include omega(2n);
// 	}
// }


@mixin mobile-outer-container($size:null) {
	@include outer-container($size);
	@include media($tablet) {
		padding: 0 flex-gutter($grid-columns/2);
	}
}

@mixin lineHeight($heightValue: 12 ){
    line-height: $heightValue + px; //fallback for old browsers
    line-height: (0.083 * $heightValue) + rem;
}
