/* =Variables
-------------------------------------------------------------- */
$base-font-size: 20px;
$base-body-color: #fff;
$icon-color: #f00;
$primary-font-color: #000;
$grey: #ededed;
$link-color: #8bceb1;
$title: $base-font-family;
$content: $base-font-family;
$shadow: rgba(0, 0, 0, .6);