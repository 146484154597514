table {
  border-collapse: collapse;
  font-feature-settings: "kern", "liga", "tnum";
  margin: $small-spacing 0;
  table-layout: fixed;
  width: 100%;
}

th {
  border-bottom: 1px solid shade($base-border-color, 25%);
  font-weight: 600;
  padding: $small-spacing 0;
  text-align: left;
}

td {
  border-bottom: $base-border;
  padding: $small-spacing 0;
}

tr,
td,
th {
  vertical-align: middle;
}
