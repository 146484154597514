html,body {
	overflow-x: hidden;
}

header {
	z-index: 10000;
	position: relative;
}
.back-to {
	@include outer-container;

	a {
		@include span-columns(3);
		@include shift(9);
		background: $red;
		color: #fff;
		padding: 0.5rem;
		font-size: rem(14);
		text-align: center;

		@include media($tablet) {
			@include fill-parent();
			@include shift(0);
		}
	}

	margin-bottom: 2rem;

	@include media($old-mbp) {
		margin-bottom: 1rem;
	}

	@include media($tablet) {
		margin-bottom: 0;
	}

}

.menu-bar {
	background: #fff;
	box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.24);
	height: 96px;
	width: 100vw;

	&.fixed {
		position: fixed;
		z-index: 200;
		top: 0;

		@include media($tablet) {
				position: relative;
				top: auto;
			}
	}
	

	.bar-wrap {
		@include outer-container;
@include mobile-outer-container();
		height: 100%;
		.logo-area {
			@include span-columns(3);
			height: 100%;
			img {
				max-width: 70%;
				height: calc(96px * .666);
				position: relative;
			    top: 50%;
			    transform: translateY(-50%);
			}

			@include media($tablet) {
				@include fill-parent();
				text-align: center;
			}
		}

		.menu-wrap {
			@include span-columns(9);
			height: 100%;
			nav {
				float: right;
				height: calc(96px * .666);
				position: relative;
			    top: 50%;
			    transform: translateY(-50%);
				ul {
					list-style-type: none;
					height: 100%;
					li {
						display: inline-block;
						float: left;
						position: relative;
					    top: 50%;
					    transform: translateY(-50%);

					    &:not(:last-of-type) {
					    	border-right: 1px solid black;
					    	padding: 0 1.5rem;
					    }

					    &:last-of-type {
					    	padding-left: 1.5rem;
					    }

					    &:nth-of-type(4) {
					    	border-right: 0;
					    }

					    a {
					    	font-weight: 700;
					    	text-transform: uppercase;
					    	font-size: rem(14);


					    	&:hover {
					    		color: $red;
					    	}

					    	&.enquire {
					    		background: $red;
					    		color: #fff;
					    		padding: 0.5rem 1.5rem;
					    		-webkit-font-smoothing: antialiased;
					    	}
					    }
					}
				}
			}
			@include media($tablet) {
				display: none;
			}
		}
	}
}

.hero-wrap {
	height: calc(100vh - 165px);

	@include media($old-mbp) {
		height: calc(100vh - 149px);
	}

	@include media($mobile) {
			height: calc(100vh + 44px);
		}

	&.fixed {
		height: calc(100vh - 69px);

		@include media($tablet) {
			height: calc(100vh + 44px);
		}

		@include media($sideways-phone) {
			height: 140vh;
		}

		@include media($iphone-5) {
			height: 120vh;
		}

		@include media($sideways-5) {
			height: 160vh;
		}

	}

	@include media($sideways-phone) {
		height: 140vh;
	}

	@include media($iphone-5) {
		height: 120vh;
	}

	@include media($sideways-5) {
			height: 160vh;
		}

	.hero-content {
		z-index: 100;
	    position: absolute;
	    top: 25%;
	    left: 50%;
	    transform: translate(-50%);
	    text-align: center;
	    color: #fff;
	    width: 40vw;
	    min-width: 468px;
	    @include media($old-mbp) {
	    	width: 70vw;
	    }
	    @include media($laptop) {
			width: 80vw;
		}
		@include media($tablet) {
			min-width: 0;
			width: 90vw;
		}
		@include media($mobile) {
			top: 145px;
		}
		@include media($sideways-phone) {
			top: 145px;
		}
		h1, h2 {
			font-family: 'Quicksand', sans-serif;
			color: #fff;
			font-weight: 400;
			text-transform: uppercase;
		}

		h1 {
			font-size: rem(100);
			line-height: rem(50);

			@include media($old-mbp) {
				font-size: rem(80);
				line-height: rem(40);
			}

			@include media($tablet) {
				font-size: rem(60);
				line-height: 1rem;
			}

			img {
				width: 80%;
    			margin-bottom: 2rem;
			}
		}
		h2 {
			font-size: rem(26);
		}

		p {
			font-size: rem(20);
			font-weight: 300;

			@include media($tablet) {
				font-size: 1.1rem;
			}
		}

	}

	a {
		position: absolute;
		z-index: 1;
		left: 50%;
		transform: translateX(-50%);
		bottom: 15vh;

		@include media($height-check) {
			bottom: 5vh;
		}

		@include media($mobile) {
			bottom: -110px;
		}

		@include media($sideways-phone) {
			bottom: -200px;
		}

		@include media($iphone-5) {
			bottom: -280px;
		}
		@include media($sideways-5) {
			bottom: -290px;
		}
	}
}

.hero {
	position: fixed;
	height: 100vh;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-size: cover;
	z-index: 1;
}

.hero-overlay {
    position: absolute;
    top: -24.8rem;
    width: 100%;
    height: 397px;
    display: block;
    pointer-events: none;
    background: url(../img/hero-overlay.png) center bottom no-repeat;
    z-index: 1;
    background-size: cover;
}

.info-cta-bar-wrap {
	@include background-image(linear-gradient(to right, $red, #921411));
	position:relative;
	z-index: 1;

	.info-cta-bar-content {
		@include outer-container;
@include mobile-outer-container();
		@include media($tablet) {
			padding-top: 5rem;
			padding-bottom: 5rem;
		}
		text-align: center;
		color: #fff;
		padding: 5rem 0;
		h2 {
			font-size: rem(40);
			font-weight: 400;
			color: #fff;
		}
		p {
			font-size: rem(26);

			a.enquire {
				background-color: #fff;
				color: $red;
				padding: 0.5rem 1.5rem;
			}
		}
	}
}
.lifestyle {
	position: relative;
	z-index: 1;
background: #fff;
padding: 5rem 0;
}

.section {
	@include outer-container;
@include mobile-outer-container();
	padding: 5rem 0;
	position: relative;
	z-index: 1;
	height: 110rem;
	background: #fff;


	@include media($tablet) {
		height: 2660px;
	}

	@include media($mobile) {
		height: 2450px;
	}

	&:after {
		content: "";
		height: 190vh;
		display: block;
	}

	img {
			box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.24);
		}

	.box-1 {
		width: 450px;
		height: 450px;
		font-size: rem(38);
		border: 2px solid #000;
		padding: 2rem;
		z-index: 4;
		background: #fff;
		position: absolute;

		@include media($tablet) {
			@include fill-parent();
			height: auto;
		}
	}

	.image-1 {
		z-index: 3;
		
		position: absolute;
		right: 130px;
		top: 7rem;

		@include media($tablet) {
			@include fill-parent();
			height: auto;
		}
	}

	.image-2 {
		z-index: 2;
		position: absolute;
		left: 305px;
    	top: 26rem;
	}

	.box-2 {
		width: 280px;
		height: 280px;
		border: 2px solid #000;
		z-index: 1;

		position: absolute;
		right: 20px;;
		top: 40rem;

		@include media($tablet) {
			display: none !important;
		}
	}

	.image-3 {
		z-index: 3;
		position: absolute;
		left: 20px;
		top: 44rem;

		@include media($tablet) {
			display: none !important;
		}
	}

	.image-4 {
		position: absolute;
		z-index: 2;
		right: 0;
		top: 55rem;

		@include media($tablet) {
			display: none !important;
		}
	}

	.image-5 {
		position: absolute;
		z-index: 3;
		left: 320px;
		top: 55rem;
	}

	.image-6 {
		position: absolute;
		z-index: 1;
		left: 0;
		top: 70rem;
	}

	.image-7 {
		position: absolute;
		z-index: 1;
		right: 90px;
		top: 75rem;
	}

	.image-8 {
		position: absolute;
		z-index: 3;
		left: 200px;
		top: 85rem;
	}

	.box-3 {
		position: absolute;
		z-index: 4;
		right: 6rem;
		top: 85rem;
		width: 400px;
		height: 400px;
		font-size: rem(38);
		border: 2px solid #000;
		padding: 2rem;
		background: #fff;
	}

	.vs-transform {
		@include media($tablet) {
			position: relative;
			display: block;
			@include fill-parent();
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			transform: translateY(0) !important;
			height: auto;
			margin: 1rem auto;
			text-align: center;
			font-size: rem(30);
		}
	}

}

.experience-wrap {
	background: url(../img/white-overlay.png) #eee center bottom no-repeat;
	background-size: cover;
	padding: 5rem 0;
	position:relative;
	z-index: 1;

	iframe {
		width: 80%;
		height: 400px;
		margin: 0 auto;
	}

	.experience-area {
		@include outer-container;
@include mobile-outer-container();

		text-align: center;
		h2, p {
			color: #010101;
		}

		h2 {
			font-size: rem(40);
			font-weight: 400;

		}

		p {
			margin-bottom: 2rem;
		}
	}
}

#map {
	width: 100vw;
	height: 400px;
	position:relative;
	z-index: 1;
	border-top: 2px solid #000;
	border-bottom: 2px solid #000;

	@include  media($tablet) {
		height: 200px;
	}
}

.int-wrap {
	background: url(../img/pic-bg.png) #fff center top no-repeat;
	background-size: cover;
	padding: 5rem;
	position:relative;
	z-index: 1;
	text-align: center;

	h2, p {
			color: #010101;
		}

		h2 {
			font-size: rem(40);
			font-weight: 400;

		}

		p {
			margin-bottom: 2rem;
		}

	@include media($tablet) {
		padding: 5rem 0;
	}

	.row {
		@include outer-container;
		@include mobile-outer-container();
		margin-bottom: 1.5rem;

		@include media($tablet) {
			margin-bottom: 0;
		}

	}
}

.floorplans {
	background: #eee;
	padding: 3rem 0;
	position:relative;
	z-index: 1;

	.plan {
		@include outer-container;
@include mobile-outer-container();
		padding: 2rem 0;

		@include media($tablet) {
				padding-bottom: 2rem;
				padding-top: 2rem;
			}

		.left {
			@include span-columns(6);
			@include media($tablet) {
				@include fill-parent();
			}
		}
		.right {
			@include span-columns(6);

			@include media($tablet) {
				@include fill-parent();
			}
		}
		h2 {
			color: #000;
			font-size: rem(40);
			font-weight: 400;
		}

		h3 {
			color: $red;
			font-size: rem(30);
			margin-bottom: 0;
		}

		&:first-of-type {
			border-bottom: 2px solid #000;
		}

		img {
			padding: 2rem;
			background: #fff;
			max-height: 500px;

			@include media($mobile) {
				margin: 1rem 0;
			}
		}

		&#yalumba .right, &#vasse .left {
			text-align: center;
		}

		.display-key-info {
			margin-bottom: 1rem;
		}

		.display-key-info .bed {
		    background: rgba(0, 0, 0, 0) url("http://d27s1b615zjvxg.cloudfront.net/wp-content/themes/aveling_homes/images/bed-icon.png") no-repeat scroll left center;
		    border-right: 1px solid #4c4c4c;
		    font-size: rem(16);
		    font-weight: bold;
		    height: 27px;
		    line-height: 27px;
		    padding-left: 32px;
		    padding-right: 10px;
		}

		.display-key-info .bath {
		    background: rgba(0, 0, 0, 0) url("http://d27s1b615zjvxg.cloudfront.net/wp-content/themes/aveling_homes/images/bath-icon.png") no-repeat scroll left center;
		    border-right: 1px solid #4c4c4c;
		    font-size: rem(16);
		    font-weight: bold;
		    height: 27px;
		    line-height: 27px;
		    margin-left: 5px;
		    padding-left: 27px;
		    padding-right: 10px;
		}

		.display-key-info .garage {
		    background: rgba(0, 0, 0, 0) url("http://d27s1b615zjvxg.cloudfront.net/wp-content/themes/aveling_homes/images/garage-icon.png") no-repeat scroll left center;
		    font-size: rem(16);
		    font-weight: bold;
		    height: 27px;
		    line-height: 27px;
		    margin-left: 5px;
		    padding-left: 27px;
		    padding-right: 10px;
		}

		.enquire {
			background-color: $red;
			color: #fff;
			padding: 0.5rem 1.5rem;
			margin-top: 2rem;
			display: inline-block;

			@include media($mobile) {
				margin-bottom: 2rem;
			}
		}
	}
}

.contact-wrap {
	background: url(../img/white-overlay.png) #fff center bottom no-repeat;
	background-size: cover;
	padding: 5rem 0;
	text-align: center;
	position:relative;
	z-index: 1;
	margin-top: -0.6rem;
	form {
		@include outer-container;
@include mobile-outer-container();
		border: 2px solid #000;
		padding: 2rem 0;
		background: #fff;

		@include media($tablet) {
			border: 0;
		}

		.form-area {
			width: 80%;
			margin: 0 auto;

			input, select {
				width: 45%;
				margin: 0.5rem auto ;
				display: inline-block;
				padding: 1rem;
				border: 2px solid #000;
				color: #000;
				opacity: 1;
				border-radius: 0;
				text-indent: 0;
				font-size: rem(16);

				@include media($tablet) {
					@include fill-parent();
				}
			}

			select {
				-webkit-appearance: none;  /*Removes default chrome and safari style*/
				-moz-appearance: none; /* Removes Default Firefox style*/
		        appearance:none;
				overflow: hidden;
				background: url(../img/form-arrow.png) no-repeat right 1rem center;

				//REMOVE THIS WHEN ADDING FLOORPLAN
				&:last-of-type {
				    float: left;
				    margin-left: 36px;

				    @include media($mobile) {
				    	float: none;
				    	margin-left: 0;
				    }
				}
			}

			option {
				text-indent: 0;
			}

			textarea {
				width: 91%;
				padding: 1rem;
				margin: 0.5rem auto ;
				border: 2px solid #000;
				color: #000;
				border-radius: 0;

				@include media($tablet) {
					@include fill-parent();
				}
			}

			input[type="submit"] {
				background: $red;
				margin: 0.5rem auto ;
				border: 0;
				color: #fff;
				font-weight: 400;
			}
		}
	}

	p {
		width: 90%;
		margin: 1rem auto;

		.phone {
			color: $red;
			background: rgba(0, 0, 0, 0) url("../img/phone.png") no-repeat scroll left center;
		    font-weight: bold;
		    height: 27px;
		    line-height: 27px;
		    margin-left: 5px;
		    padding-left: 27px;
		    padding-right: 10px;
		}
	}

	h2 {
		color: #000;
		font-size: rem(40);
		font-weight: 400;
	}
}

input {
	::-webkit-input-placeholder {
	   color: #000 !important;
	   opacity: 1 !important;
	}

	:-moz-placeholder { /* Firefox 18- */
	   color: #000 !important;
	   opacity: 1 !important;  
	}

	::-moz-placeholder {  /* Firefox 19+ */
	   color: #000 !important;
	   opacity: 1 !important;  
	}

	:-ms-input-placeholder {  
	   color: #000 !important;
	   opacity: 1 !important;  
	}
}

footer {
	background: #2f2f2f;
	position:relative;
	z-index: 1;

	.footer-wrap {
		@include outer-container;
@include mobile-outer-container();

		.logo-area {
			@include span-columns(4);
			@include media($tablet) {
				@include fill-parent();
				text-align: center;
			}
			height: 100%;
			img {
				max-width: 50%;
				height: calc(96px * .666);
				position: relative;
				margin: 1rem 0;
			}
		}

		.right {
			@include span-columns(5);
			@include shift(3);


			p, p span.AVANSERnumber {
				color: #fff;
				font-size: rem(22);
				margin: 1rem 0;
				text-align: right;
				line-height: rem(32);
				display: inline-block;
				span {
					color: #858484;
					display: block;
					font-size: rem(14);

					@include media($tablet) {
						font-size: rem(12);
					}
				}
				@include media($tablet) {
					margin-bottom: 0;
				}
			}

			p {
				&:first-of-type {
					float: right;

					@include media($tablet) {
						float: none;
						margin: 0 auto;
    					display: block;
					}
				}
			}

			@include media($tablet) {
				@include fill-parent();
				@include shift(0);
				p {
					text-align: center;
				}
				
			}
		}
	}
}

.int-img {
	width: 100vw;
	position:relative;
	z-index: 1;
}

a[href^=tel] { color: inherit; }

.featherlight .featherlight-image {
	max-height: 85vh;
}

.int-wrap .simple-image img {
	display: block;
    border: 2px solid black;
    box-sizing: border-box;
    margin: 0 auto 1rem auto;
}

.int-wrap .simple-image a:nth-child(even) img {
	margin-left: 2%;
}

.int-wrap .simple-image a {
	position: relative;
}

.int-wrap .simple-image a span {
    display: block;
    text-align: center;
    width: 251px;
    margin: 0 auto;
    font-size: rem(14px);
}

.int-wrap .simple-image .flex-direction-nav a.flex-prev, .int-wrap .simple-image .flex-direction-nav a.flex-next {
	position: absolute;
	margin-top: 0;
}
.int-wrap .simple-image .flex-direction-nav a {
	background: $red;
	color: #fff;
	padding: 0.5rem 1rem;
}
.int-wrap .simple-image .flex-direction-nav a.flex-prev {
	left: 0;
}
.int-wrap .simple-image .flex-direction-nav a.flex-next {
	right: 0;
}
.int-wrap .flex-direction-nav li { list-style: none;}

.int-wrap .simple-image .flex-direction-nav {
	position: relative;
}



/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp
{
	padding: 0;
	margin: 0;
	border: 0;
	outline: none;
	vertical-align: top;
}

.fancybox-wrap {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 8020;
}

.fancybox-skin {
	position: relative;
	background: #f9f9f9;
	color: #444;
	text-shadow: none;
	-webkit-border-radius: 4px;
	   -moz-border-radius: 4px;
	        border-radius: 4px;
}

.fancybox-opened {
	z-index: 8030;
}

.fancybox-opened .fancybox-skin {
	-webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
	   -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
	        box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.fancybox-outer, .fancybox-inner {
	position: relative;
}

.fancybox-inner {
	overflow: auto;
}

.fancybox-type-iframe .fancybox-inner {
	-webkit-overflow-scrolling: touch;
}

.fancybox-error {
	color: #444;
	font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
	margin: 0;
	padding: 15px;
	white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
	display: block;
	width: 100%;
	height: 100%;
}

.fancybox-image {
	max-width: 100%;
	max-height: 100%;
}

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
	background-image: url('fancybox_sprite.png');
}

#fancybox-loading {
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -22px;
	margin-left: -22px;
	background-position: 0 -108px;
	opacity: 0.8;
	cursor: pointer;
	z-index: 8060;
}

#fancybox-loading div {
	width: 44px;
	height: 44px;
	background: url('fancybox_loading.gif') center center no-repeat;
}

.fancybox-close {
	position: absolute;
	top: -18px;
	right: -18px;
	width: 36px;
	height: 36px;
	cursor: pointer;
	z-index: 8040;
}

.fancybox-nav {
	position: absolute;
	top: 0;
	width: 40%;
	height: 100%;
	cursor: pointer;
	text-decoration: none;
	background: transparent url('blank.gif'); /* helps IE */
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	z-index: 8040;
}

.fancybox-prev {
	left: 0;
}

.fancybox-next {
	right: 0;
}

.fancybox-nav span {
	position: absolute;
	top: 50%;
	width: 36px;
	height: 34px;
	margin-top: -18px;
	cursor: pointer;
	z-index: 8040;
	visibility: hidden;
}

.fancybox-prev span {
	left: 10px;
	background-position: 0 -36px;
}

.fancybox-next span {
	right: 10px;
	background-position: 0 -72px;
}

.fancybox-nav:hover span {
	visibility: visible;
}

.fancybox-tmp {
	position: absolute;
	top: -99999px;
	left: -99999px;
	visibility: hidden;
	max-width: 99999px;
	max-height: 99999px;
	overflow: visible !important;
}

/* Overlay helper */

.fancybox-lock {
    overflow: hidden !important;
    width: auto;
}

.fancybox-lock body {
    overflow: hidden !important;
}

.fancybox-lock-test {
    overflow-y: hidden !important;
}

.fancybox-overlay {
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	display: none;
	z-index: 10000;
	background: url('fancybox_overlay.png');
}

.fancybox-overlay-fixed {
	position: fixed;
	bottom: 0;
	right: 0;
}

.fancybox-lock .fancybox-overlay {
	overflow: auto;
	overflow-y: scroll;
}

/* Title helper */

.fancybox-title {
	visibility: hidden;
	font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
	position: relative;
	text-shadow: none;
	z-index: 8050;
}

.fancybox-opened .fancybox-title {
	visibility: visible;
}

.fancybox-title-float-wrap {
	position: absolute;
	bottom: 0;
	right: 50%;
	margin-bottom: -35px;
	z-index: 8050;
	text-align: center;
}

.fancybox-title-float-wrap .child {
	display: inline-block;
	margin-right: -100%;
	padding: 2px 20px;
	background: transparent; /* Fallback for web browsers that doesn't support RGBa */
	background: rgba(0, 0, 0, 0.8);
	-webkit-border-radius: 15px;
	   -moz-border-radius: 15px;
	        border-radius: 15px;
	text-shadow: 0 1px 2px #222;
	color: #FFF;
	font-weight: bold;
	line-height: 24px;
	white-space: nowrap;
}

.fancybox-title-outside-wrap {
	position: relative;
	margin-top: 10px;
	color: #fff;
}

.fancybox-title-inside-wrap {
	padding-top: 10px;
}

.fancybox-title-over-wrap {
	position: absolute;
	bottom: 0;
	left: 0;
	color: #fff;
	padding: 10px;
	background: #000;
	background: rgba(0, 0, 0, .8);
}

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
	   only screen and (min--moz-device-pixel-ratio: 1.5),
	   only screen and (min-device-pixel-ratio: 1.5){

	#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
		background-image: url('fancybox_sprite@2x.png');
		background-size: 44px 152px; /*The size of the normal image, half the size of the hi-res image*/
	}

	#fancybox-loading div {
		background-image: url('fancybox_loading@2x.gif');
		background-size: 24px 24px; /*The size of the normal image, half the size of the hi-res image*/
	}
}

#thanks-overlay {
    background: #000;
    background: rgba(0, 0, 0, 0.7);
    display: none;
    float: left;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
}

#gform-notification {
    background: #fff;
    border-radius: 10px;
    display: none;
    margin: auto;
    max-width: 50%;
    padding: 5rem;
    position: fixed;
    top: 8rem; left: 0; right: 0;
    text-align: center;
    width: 100%;
    z-index: 101;
}

#gform-notification p {
    margin: 0 auto;
    max-width: 80%;
}

#gform-notification .button {
    margin: 4rem 0 0;
    padding: 12px 24px;
    color: #fff;
    background-color: #b82d1f;
    text-transform: uppercase;
    position: relative;
    top: 2rem;
}

#gform-notification .cross:after {
    display: inline-block;
    content: "\00d7"; /* This will render the 'X' */
    top: 10px;
    right: 10px;
    position: absolute;
    width: 30px;
    height: 30px;
    font-size: 30px;
}

#gform-notification img {
    margin-bottom: 2rem;
    display: inline;
}

@media screen and (max-width: 768px) {
    #gform-notification {
        max-width: 70%;
    }

    #gform-notification img {
        height: 100px;
    }
}

label.error {
	display: none !important;
}

input.error, select.error, textarea.error {
	border: 2px solid $red !important;
}

.flex-viewport {
	@include media($mobile) {
		margin-left: -20px;
	}
	@include media($iphone-5) {
		margin-left: -40px;
	}
}